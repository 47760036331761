import { render, staticRenderFns } from "./SingleWork.vue?vue&type=template&id=503b6234&scoped=true"
import script from "./SingleWork.vue?vue&type=script&lang=js"
export * from "./SingleWork.vue?vue&type=script&lang=js"
import style0 from "./SingleWork.vue?vue&type=style&index=0&id=503b6234&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "503b6234",
  null
  
)

export default component.exports
import Vue from 'vue'
import VueRouter from "vue-router"
Vue.use(VueRouter)

// 引入页面
import AboutView from '@/views/AboutView.vue'
import WorksView from '@/views/WorksView'
import BlogView from '@/views/BlogView'
import ContactView from '@/views/ContactView'

import GPUParallelComputing from '@/views/Works/GPUParallelComputing.vue'
import EWaste from '@/views/Works/EWaste'
import OpenGL from '@/views/Works/OpenGL'
import NGrams from '@/views/Works/NGrams'
import HealthyExpert from '@/views/Works/HealthyExpertView'
import BirdWatching from '@/views/Works/BirdWatchingView'
import TourVisit from '@/views/Works/TourVisitView'
import Robotic from '@/views/Works/RoboticView'
import TheBigWalk from '@/views/Works/TheBigWalkView'
import TextProcessing from '@/views/Works/TextProcessingView'
import DataDriven from '@/views/Works/DataDrivenView'
import RentSystem from '@/views/Works/RentSystemView'
import EightOff from '@/views/Works/EightOffView'
import Eleme from '@/views/Works/ElemeView'

import AIO from '@/views/Blogs/AIOView'
import PVE from '@/views/Blogs/PVEView'
import OpenWrt from '@/views/Blogs/OpenWrtView'
import Linux from '@/views/Blogs/LinuxView'
import Synology from '@/views/Blogs/SynologyView'
import NotFound from "@/views/NotFound.vue";
import CatView from "@/views/CatView.vue";
import CocktailView from "@/views/CocktailView.vue";
import MusicBuddyIOS from "@/views/Works/NetifyView.vue";
import MusicBuddy from "@/views/Works/MusicBuddy.vue";
import TestView from "@/views/TestView.vue";
import NetifyView from "@/views/Works/NetifyView.vue";
import JoFind from "@/views/Works/JoFind.vue";
import MeowID from "@/views/Works/MeowID.vue";
import whatToMix from "@/views/Works/WhatToMix.vue";
import MotorBike from "@/views//MotorBike.vue";

// 规则数组
const routes = [
  {
    path: "/",
    name: 'about',
    component: AboutView,
  },

  {
    path: "/motorbike",
    name: 'motorbike',
    component: MotorBike,
  },

  // 项目路由
  {
    path: "/works",
    name: 'works',
    component: WorksView,
  },
  {
    path: '/works/whatToMix',
    component: whatToMix,
  },
  {
    path: '/works/meowID',
    component: MeowID,
  },
  {
    path: '/works/joFind',
    component: JoFind,
  },
  {
    path: '/works/musicBuddy',
    component: MusicBuddy,
  },
  {
    path: '/works/Netify',
    component: MusicBuddyIOS,
  },
  {
    path: '/works/nGrams',
    component: NGrams,
  },
  {
    path: '/works/OpenGL',
    component: OpenGL,
  },
  {
    path: '/works/HealthyExpert',
    component: HealthyExpert,
  },
  {
    path: '/works/BirdWatching',
    component: BirdWatching,
  },
  {
    path: '/works/TourVisitView',
    component: TourVisit,
  },
  {
    path: '/works/Robotic',
    component: Robotic,
  },
  {
    path: '/works/TheBigWalk',
    component: TheBigWalk,
  },
  {
    path: '/works/TextProcessing',
    component: TextProcessing,
  },
  {
    path: '/works/DataDriven',
    component: DataDriven,
  },
  {
    path: '/works/RentSystem',
    component: RentSystem,
  },
  {
    path: '/works/EightOff',
    component: EightOff,
  },
  {
    path: '/works/netify',
    component: NetifyView,
  },
  {
    path: '/works/Eleme',
    component: Eleme,
  },
  {
    path: '/works/EWaste',
    component: EWaste,
  },
  {
    path: '/works/GPUParallelComputing',
    component:  GPUParallelComputing,
  },


  //博客路由
  {
    path: "/blogs",
    name: 'blogs',
    component: BlogView
  },
  {
    path: '/blogs/AIO',
    component: AIO,
  },
  {
    path: '/blogs/PVE',
    component: PVE,
  },
  {
    path: '/blogs/OpenWrt',
    component: OpenWrt,
  },
  {
    path: '/blogs/Linux',
    component: Linux,
  },
  {
    path: '/blogs/Synology',
    component: Synology,
  },

  {
    path: "/contact",
    name: 'contact',
    component: ContactView
  },

  //相册路由
  {
    path: "/cat",
    name: 'cat',
    component: CatView
  },
  {
    path: "/cocktail",
    name: 'cocktail',
    component: CocktailView
  },
  {
    path: '/test',
    name: 'test',
    component: TestView,
  },
  {
    path: '*',
    name: '404',
    component: NotFound,
  }

]


//生成路由对象
const router = new VueRouter({
  // mode: 'history',
    // routes: routes
    // scrollBehavior: () => ({y: 0}),
    routes, //routes 时固定key(传入规则数组)
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        // 恢复滚动位置
        return savedPosition
      } else {
        // 滚动到页面顶部
        return { selector: 'body', behavior: 'smooth' };
      }
    }
})


// // //路由守卫
// router.beforeEach((to, from, next) => {
//   if (to.path != '/' && !sessionStorage.getItem('id')) {
//     return router.push({
//       path:'/'
//     })
//   } else {
//     next()
//   }
// })
  
export default router
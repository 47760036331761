<template>
    <div class="container-lg gochi-hand-regular wow fadeIn" data-wow-duration="3s" style="overflow: hidden">
        <div class="title mt-3">
            <h1 class="px-5 my-3 fw-bold">{{ $t('message.project.header') }}</h1>
        </div>

        <div class="row m-0 p-0 w-100 d-flex justify-content-center">
            <picture class="w-100 mx-auto img-fluid d-block m-0 p-0">
                <source media="(prefers-color-scheme: dark)" srcset="https://raw.githubusercontent.com/platane/platane/output/github-contribution-grid-snake-dark.svg">
                <source media="(prefers-color-scheme: light)" srcset="https://raw.githubusercontent.com/platane/platane/output/github-contribution-grid-snake.svg">
                <img alt="github contribution grid snake animation" class="w-100 mx-auto" src="https://raw.githubusercontent.com/platane/platane/output/github-contribution-grid-snake.svg">
            </picture>
        </div>
        
        <div class="row wow fadeInUp" data-wow-duration="3s" >
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 p-0 my-3 wow fadeIn">
                <SingleWork toURL='/works/whatToMix'
                            :title="$t('message.project.whatToMix.title')"
                            :abstract="$t('message.project.whatToMix.abstract')"
                            :img="require('@/assets/worksDetail/whatToMix/WhatToMix.png')"
                            :tags="['Vue','Bootstrap']">
                </SingleWork>
            </div>

            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 p-0 my-3 wow fadeIn">
                <SingleWork toURL='/works/meowid'
                            :title="$t('message.project.meowID.title')"
                            :abstract="$t('message.project.meowID.abstract')"
                            :img="require('@/assets/worksDetail/meowID/screenshot.png')"
                            :tags="['Machine Learning', 'CNN', 'Flask', 'Vue']">
                </SingleWork>
            </div>

            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 p-0 my-3 wow fadeIn">
                <SingleWork toURL='/works/joFind'
                            :title="$t('message.project.joFind.title')"
                            :abstract="$t('message.project.joFind.abstract')"
                            :img="require('@/assets/worksDetail/joFind/jofind.png')"
                            :tags="['Vue3', 'Node.js', 'Socket.io']">
                </SingleWork>
            </div>


            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 p-0 my-3 wow fadeIn">
                <SingleWork toURL='/works/musicBuddy'
                            :title="$t('message.project.musicBuddy.title')"
                            :abstract="$t('message.project.musicBuddy.abstract')"
                            :img="require('@/assets/worksDetail/musicBuddy/musicBuddy.png')"
                            :tags="['Vue2', 'Node.js', 'NLP', 'Machine Learning']">
                </SingleWork>
            </div>

            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 p-0 my-3 wow fadeIn">
                <SingleWork toURL='/works/Netify'
                            :title="$t('message.project.netify.title')"
                            :abstract="$t('message.project.netify.abstract')"
                            :img="require('@/assets/worksDetail/netify/musicBuddyIOS.png')"
                            :tags="['SwiftUI', 'Node.js', 'iOS', 'Mobile']">
                </SingleWork>
            </div>

            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 p-0 my-3 wow fadeIn">
                <SingleWork toURL='/works/eWaste'
                            :title="$t('message.project.eWaste.title')"
                            :abstract="$t('message.project.eWaste.abstract')"
                            :img="require('@/assets/worksDetail/ewaste/ewaste.png')"
                            :tags="['EJS', 'Node.js', 'MongoDB', 'Axios', 'Bootstrap']">
                </SingleWork>
            </div>


            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 p-0 my-3 wow fadeIn">
                <SingleWork toURL='/works/HealthyExpert'
                            :title="$t('message.project.healthyExpert.title')"
                            :abstract="$t('message.project.healthyExpert.abstract')"
                            :img="require('@/assets/works/healthyExpertPromo.png')"
                            :tags="['Kotlin', 'Android', 'Mysql', 'Node.js', 'Mobile Sensing']">
                </SingleWork>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 p-0 my-3 wow fadeIn">
                <SingleWork toURL='/works/BirdWatching'
                            :title="$t('message.project.birdWatching.title')"
                            :abstract="$t('message.project.birdWatching.abstract')"
                            :img="require('@/assets/worksDetail/birdWatching/birdWatching.png')"
                            :tags="['EJS', 'Node.js', 'MongoDB', 'IndexedDB', 'Socket.io', 'ServiceWorker']">
                </SingleWork>
            </div>

            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 p-0 my-3 wow fadeIn">
                <SingleWork toURL='/works/TheBigWalk'
                            :title="$t('message.project.theBigWalk.title')"
                            :abstract="$t('message.project.theBigWalk.abstract')"
                            :img="require('@/assets/worksDetail/theBigWalk/theBigWalk.png')"
                            :tags="['Ruby on Rails', 'Bootstrap', 'Postgresql', 'Location']">
                </SingleWork>
            </div>

<!--            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 p-0 my-3 wow fadeIn">-->
<!--                <SingleWork toURL='/works/TourVisitView'-->
<!--                            :title="$t('message.project.tourVisitView.title')"-->
<!--                            :abstract="$t('message.project.tourVisitView.abstract')"-->
<!--                            :img="require('@/assets/worksDetail/tourVisit/tourVisit.png')"-->
<!--                            :tags="['Kotlin', 'Android', 'Room', 'Background Service', 'Mobile Sensing']">-->
<!--                </SingleWork>-->
<!--            </div>-->
<!--            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 p-0 my-3 wow fadeIn">-->
<!--                <SingleWork toURL='/works/RentSystem'-->
<!--                            :title="$t('message.project.rentSystem.title')"-->
<!--                            :abstract="$t('message.project.rentSystem.abstract')"-->
<!--                            :img="require('@/assets/worksDetail/rentSystem/rentSystem.png')"-->
<!--                            :tags="['JAVA', 'Swing', 'MySQL']">-->
<!--                </SingleWork>-->
<!--            </div>-->

<!--            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 p-0 my-3 wow fadeIn">-->
<!--                <SingleWork toURL='/works/OpenGL'-->
<!--                            :title="$t('message.project.openGL.title')"-->
<!--                            :abstract="$t('message.project.openGL.abstract')"-->
<!--                            :img="require('@/assets/works/openGL.png')"-->
<!--                            :tags="['OpenGL', 'Swing', 'JAVA']">-->
<!--                </SingleWork>-->
<!--            </div>-->
<!--            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 p-0 my-3">-->
<!--                <SingleWork toURL='/works/GPUParallelComputing'-->
<!--                            :title="$t('message.project.cuda.title')"-->
<!--                            :abstract="$t('message.project.cuda.abstract')">-->
<!--                    <template v-slot:img>-->
<!--                            <img src="../assets/logo/cuda.jpeg"/>-->
<!--                    </template>-->
<!--                </SingleWork>-->
<!--            </div>-->

<!--            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 p-0 my-3">-->
<!--                <SingleWork toURL='/works/nGrams'-->
<!--                            :title="$t('message.project.nGrams.title')"-->
<!--                            :abstract="$t('message.project.nGrams.abstract')">-->
<!--                    <template v-slot:img>-->
<!--                            <img src="../assets/works/ngrams.png"/>-->
<!--                    </template>-->
<!--                </SingleWork>-->
<!--            </div>-->
<!--            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 p-0 my-3">-->
<!--                <SingleWork toURL='/works/Robotic'-->
<!--                            :title="$t('message.project.ros.title')"-->
<!--                            :abstract="$t('message.project.ros.abstract')">-->
<!--                    <template v-slot:img>-->
<!--                        <img src="../assets/works/robotic.png"/>-->
<!--                    </template>-->
<!--                </SingleWork>-->
<!--            </div>-->
<!--            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 p-0 my-3">-->
<!--                <SingleWork toURL='/works/TextProcessing'-->
<!--                            :title="$t('message.project.textProcessing.title')"-->
<!--                            :abstract="$t('message.project.textProcessing.abstract')">-->
<!--                    <template v-slot:img>-->
<!--                        <img src="../assets/works/textProcessing.png"/>-->
<!--                    </template>-->
<!--                </SingleWork>-->
<!--            </div>-->
<!--            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 p-0 my-3">-->
<!--                <SingleWork toURL='/works/DataDriven'-->
<!--                            :title="$t('message.project.dataDriven.title')"-->
<!--                            :abstract="$t('message.project.dataDriven.abstract')">-->
<!--                    <template v-slot:img>-->
<!--                        <img src="../assets/works/dataDriven.png"/>-->
<!--                    </template>-->
<!--                </SingleWork>-->
<!--            </div>-->
<!--            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 p-0 my-3">-->
<!--                <SingleWork toURL='/works/EightOff'-->
<!--                            :title="$t('message.project.haskell.title')"-->
<!--                            :abstract="$t('message.project.haskell.abstract')">-->
<!--                    <template v-slot:img>-->
<!--                        <img src="../assets/works/haskell.png"/>-->
<!--                    </template>-->
<!--                </SingleWork>-->
<!--            </div>-->

<!--            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 p-0 my-3">-->
<!--                <SingleWork toURL='/works/NeteaseMusic'-->
<!--                            :title="$t('message.project.neteaseMusic.title')"-->
<!--                            :abstract="$t('message.project.neteaseMusic.abstract')">-->
<!--                    <template v-slot:img>-->
<!--                        <img src="../assets/works/wyy.png"/>-->
<!--                    </template>-->
<!--                </SingleWork>-->
<!--            </div>-->
<!--            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 p-0 my-3">-->
<!--                <SingleWork toURL='/works/Eleme'-->
<!--                            :title="$t('message.project.eleme.title')"-->
<!--                            :abstract="$t('message.project.eleme.abstract')">-->
<!--                    <template v-slot:img>-->
<!--                        <img src="../assets/works/eleme.png"/>-->
<!--                    </template>-->
<!--                </SingleWork>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
import SingleWork from '@/components/SingleWork';

export default {
    components: {
        SingleWork
    },
}
</script>

<style lang="css" scoped>
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');

.gochi-hand-regular {
    font-family: "Gochi Hand", cursive;
    font-weight: 600;
    font-style: normal;
}


/* #swipe-container{
    display: flex;
    justify-content: center;
    margin-block: 50px;
} */


/* #swipe-container .img-container{
    display: flex;
    justify-content: center;
} */

/* .img-container{
    width: 100%;
} */
.title{
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title h1{
    border-bottom: 3px solid #FF6666;
}

</style>
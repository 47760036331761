<template>
    <div class="card shadow">
        <div class="tools">
            <div class="circle">
                <span class="red box"></span>
            </div>
            <div class="circle">
                <span class="yellow box"></span>
            </div>
            <div class="circle">
                <span class="green box"></span>
            </div>
        </div>
        <div class="card__content">
            <div class="row g-2 w-100">
                <div class="col-8 col-sm-7 col-md-5 col-lg-4 mx-auto">

                    <div class="avatar-container">
                        <img src="../assets/avatar3.png" class="img-fluid default-avatar">
                        <img src="../assets/avatar2.png" class="img-fluid hover-avatar">
                    </div>

                </div>




                <div class="col-12 col-lg-7 p-5 d-flex align-items-center flex-column justify-content-center fs-5">
                    <div class="d-flex flex-row justify-content-between w-100 my-2 p-2 card-hover">
                        <p>{{ $t('message.about.name.header') }}</p>
                        <p>{{ $t('message.about.name.content') }}</p>
                    </div>
                    <div class="d-flex flex-row justify-content-between w-100 my-2 p-2 card-hover">
                        <p>{{ $t('message.about.job.header') }}</p>
                        <p>{{ $t('message.about.job.content') }}</p>
                    </div>
                    <div class="d-flex flex-row justify-content-between w-100 my-2 p-2 card-hover">
                        <p>{{ $t('message.about.interests.header') }}</p>
                        <p>{{ $t('message.about.interests.content') }}</p>
                    </div>
                    <div class="d-flex flex-row justify-content-between w-100 my-2 p-2 card-hover">
                        <p>{{ $t('message.about.education.header') }}</p>
                        <p>{{ $t('message.about.education.content') }}</p>
                    </div>
                    <div class="d-flex flex-row justify-content-between w-100 my-2 p-2 card-hover">
                        <p>{{ $t('message.about.location.header') }}</p>
                        <p>{{ $t('message.about.location.content') }}</p>
                    </div>
                    <div class="d-flex flex-row justify-content-end w-100 my-2 p-2 ">
                        <!--                        <AnimatedButton @click="downloadResume"></AnimatedButton>-->
                    </div>
                    <div class="row w-100">
                        <contact-card></contact-card>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
import ContactCard from "@/components/ContactCard.vue";

export default {
    components: {ContactCard}
}
</script>

<style scoped>
/* 确保容器相对定位 */
.avatar-container {
    position: relative;
    width: 100%;
}

/* 默认图像 */
.default-avatar {
    display: block; /* 确保默认显示 */
    width: 100%; /* 根据需要调整宽度 */
    transition: opacity 0.3s ease-in-out; /* 添加过渡效果 */
}

/* 悬停图像初始隐藏 */
.hover-avatar {
    position: absolute; /* 绝对定位，确保两张图像重叠 */
    top: 0;
    left: 0;
    width: 100%; /* 根据需要调整宽度 */
    opacity: 0; /* 初始状态下隐藏悬停图像 */
    transition: opacity 0.3s ease-in-out; /* 添加过渡效果 */
}

/* 悬停时隐藏默认图像 */
.avatar-container:hover .default-avatar {
    opacity: 0; /* 悬停时隐藏默认图像 */
}

/* 悬停时显示悬停图像 */
.avatar-container:hover .hover-avatar {
    opacity: 1; /* 悬停时显示悬停图像 */
}


.card {
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 30px;
    z-index: 1;
}

.card-hover {
    transition: background-color 0.3s; /* 添加过渡效果 */
}

.card-hover:hover {
    background-color: lightgray; /* 鼠标移入时背景颜色为白色 */
    cursor: pointer;
}
.tools {
    display: flex;
    align-items: center;
    padding: 9px;
}

.circle {
    padding: 0 4px;
}

.box {
    display: inline-block;
    align-items: center;
    width: 10px;
    height: 10px;
    padding: 1px;
    border-radius: 50%;
}

.red {
    background-color: #ff605c;
}

.yellow {
    background-color: #ffbd44;
}

.green {
    background-color: #00ca4e;
}
</style>


var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card shadow rounded-4 p-4 overflow-hidden"},[_c('div',{staticClass:"row"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"col-12 col-lg-7 row"},[_c('div',{staticClass:"col-6 col-md-4 d-flex align-items-center my-1"},[_vm._m(2),_c('div',{staticClass:"ms-2"},[_c('h6',{staticClass:"m-0 fw-bold"},[_vm._v("1935*745*1180")]),_c('span',{staticClass:"fw-light text-muted"},[_vm._v(_vm._s(_vm.$t('message.motorbike.dimension')))])])]),_c('div',{staticClass:"col-6 col-md-4 d-flex align-items-center my-1"},[_vm._m(3),_c('div',{staticClass:"ms-2"},[_c('h6',{staticClass:"m-0 fw-bold"},[_vm._v("145 KG")]),_c('span',{staticClass:"fw-light text-muted"},[_vm._v(_vm._s(_vm.$t('message.motorbike.weight')))])])]),_c('div',{staticClass:"col-6 col-md-4 d-flex align-items-center my-1"},[_vm._m(4),_c('div',{staticClass:"ms-2"},[_c('h6',{staticClass:"m-0 fw-bold"},[_vm._v("150 CC")]),_c('span',{staticClass:"fw-light text-muted"},[_vm._v(_vm._s(_vm.$t('message.motorbike.engine')))])])]),_c('div',{staticClass:"col-6 col-md-4 d-flex align-items-center my-1"},[_vm._m(5),_c('div',{staticClass:"ms-2"},[_c('h6',{staticClass:"m-0 fw-bold"},[_vm._v("11.4/8500")]),_c('span',{staticClass:"fw-light text-muted"},[_vm._v(_vm._s(_vm.$t('message.motorbike.power')))])])]),_c('div',{staticClass:"col-6 col-md-4 d-flex align-items-center my-1"},[_vm._m(6),_c('div',{staticClass:"ms-2"},[_c('h6',{staticClass:"m-0 fw-bold"},[_vm._v("14.4/6500")]),_c('span',{staticClass:"fw-light text-muted"},[_vm._v(_vm._s(_vm.$t('message.motorbike.torsion')))])])]),_c('div',{staticClass:"col-6 col-md-4 d-flex align-items-center my-1"},[_vm._m(7),_c('div',{staticClass:"ms-2"},[_c('h6',{staticClass:"m-0 fw-bold"},[_vm._v("ABS+TCS")]),_c('span',{staticClass:"fw-light text-muted"},[_vm._v(_vm._s(_vm.$t('message.motorbike.brake')))])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h1',{staticClass:"border-bottom border-3 border-black fw-bold"},[_vm._v("NS150GX")]),_c('div',{staticClass:"d-flex"},[_c('img',{staticClass:"img-fluid",staticStyle:{"width":"70px","height":"40px"},attrs:{"src":require("@/assets/motorbike/Honda.png")}}),_c('img',{staticClass:"img-fluid",staticStyle:{"width":"90px","height":"40px"},attrs:{"src":require("@/assets/motorbike/sundiro.png")}})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-8 offset-2 col-lg-5 offset-lg-0 d-flex"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/motorbike/ns150gx_matting.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bg-secondary-subtle rounded-circle p-2",staticStyle:{"width":"40px","flex-shrink":"0"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/motorbike/size.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bg-secondary-subtle rounded-circle p-2",staticStyle:{"width":"40px","flex-shrink":"0"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/motorbike/weight.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bg-secondary-subtle rounded-circle p-2",staticStyle:{"width":"40px","flex-shrink":"0"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/motorbike/engine.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bg-secondary-subtle rounded-circle p-2",staticStyle:{"width":"40px","flex-shrink":"0"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/motorbike/power.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bg-secondary-subtle rounded-circle p-2",staticStyle:{"width":"40px","flex-shrink":"0"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/motorbike/torsion.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bg-secondary-subtle rounded-circle p-2",staticStyle:{"width":"40px","flex-shrink":"0"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/motorbike/brake.png")}})])
}]

export { render, staticRenderFns }
<template>
    <div class="container">
        <MotorbikeCard class="mt-3"></MotorbikeCard>


        <div class="line_container"><div class="line my-5"></div></div>

        <div class="row g-2">
            <div class="col-12 col-md-4"><img src="@/assets/motorbike/250210_3.jpg" class="img-fluid"></div>
            <div class="col-12 col-md-8">
                <video class="img-fluid" controls><source src="@/assets/motorbike/250210_7.mp4" type="video/mp4"></video>
            </div>
        </div>


    </div>
</template>

<script>
import MotorbikeCard from '@/components/MotorbikeCard.vue'


export default {
    components: {
        MotorbikeCard
    },

    data() {
        return {
            breakpoints: {
                0: {
                    rowPerView: 1,
                },
                576: {
                    rowPerView: 2,
                },
                768: {
                    rowPerView: 3,
                },
                992: {
                    rowPerView: 4,
                },
                1200: {
                    rowPerView: 5,
                },
                1400: {
                    rowPerView: 5,
                }
            },
            motorbikeItem:{src: ""}
        };
    },
    methods: {
        openModal(item) {
            // Populate the modal data with the clicked item
            this.catItem = { ...item };

            // Ensure the modal is shown
            const modalElement = document.getElementById('modal');
            if (modalElement) {
                const modal = new window.bootstrap.Modal(modalElement);
                modal.show();
            }
        },
    },

}
</script>

<style lang="css" scoped>
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');

.gochi-hand-regular {
    font-family: "Gochi Hand", cursive;
    font-weight: 400;
    font-style: normal;
}

#cat-pic {
    transition: 0.1s;
    cursor: pointer;
}

#cat-pic:hover {
    border: 2px solid #6e6d7a;
    cursor: pointer;
}

.cat-card {
    background-color: #e2dad1;
}
</style>
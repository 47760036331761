// src/locales/zh.js
export default {
    message: {
        navbar:{
            about: '我',
            project: '项目',
            blog: "博客",
            kitty: "猫咪",
            cocktail: "鸡尾酒",
            contact: "联系",
            motorbike: "骑行",
        },
        about:{
            hello: '嗨，我是Terence!',
            name:{
                header: '名字：',
                content: '[ 志聪 ]'
            } ,
            job: {
                header: '工作： ',
                content: '[ 网页开发🧑‍💻, 软件开发🧑‍💻 ]'
            },
            interests: {
                header: '兴趣爱好： ',
                content: '[ \'猫咪🐈\', \'音乐🎵\', \'鸡尾酒🍹\' ]'
            },
            education: {
                header: '学历: ',
                content: '[ 工程学士-软件工程, 理学硕士-计算机科学 ]'
            },
            location: {
                header: '定位：',
                content: '[ 广州，中国 ]'
            },
            apps:{
                apps: 'APPS',
                coursework: '课程作业',
                dissertation: '毕业设计',
                inspiration: '灵感创作'
            },
            edu:{
                title: '教育经历',
                name: '谢菲尔德大学',
                bachelor_title: '工程学士 软件工程',
                bachelor_degree: '一等荣誉学位',
                bachelor_courses: "涉及课程: 移动端应用开发; Web 开发; 系统设计与安全; 网络安全; 系统测试和重构; 数据驱动计算; 机器人技术; 人工智能和机器学习; 文本分析; 自动机理论;",
                master_title: '理学硕士 高级计算机科学',
                master_degree: 'Distinction',
                master_courses: "涉及课程： 软件开发; 面向对象编程和软件设计; 3D 计算机图形; 网络性能分析; GPU 并行计算; 计算机安全与取证;"
            },
            focus:{
                title: '专注领域',
                web_development:{
                    title:'网站开发',
                    content: '熟悉各种编程语言、前端和后端技术、数据库系统、网络协议和安全措施、以创建高性能、安全可靠的网站'
                },
                mobile_development:{
                    title:'移动应用程序开发',
                    content: '安卓和iOS移动软件开发是我的兴趣之一。具备开发跨平台应用和原生应用的能力，能够满足不同平台的需求。'
                },
                ai:{
                    title:'人工智能与机器学习',
                    content: '专注于开发智能系统和算法，使用数据和算法来构建和训练模型，以实现语音识别、图像处理、自然语言处理等应用。'
                },
                test:{
                    title:'软件测试与质量保证',
                    content: '编写测试计划和测试用例，执行功能测试、性能测试和安全测试，以验证软件的正确性和性能。'
                }
            },
            stack:{
                title: '技术栈'
            }
        },
        project: {
            header: "项目",
            view: "查看细节",
            stack: {
                type: "类型",
                stack: "技术栈",
                front_end: "前端",
                back_end: "后端",
                api_testing: "API测试平台",
                database: "数据库",
                editor: "源代码编辑器",
                server: "服务器",
                architecture: "架构",
                version_control: "版本控制",
                apis: "API 库",
            },
            whatToMix: {
                title: '鸡尾酒配方查询工具',
                abstract: 'WhatToMix 是一款专为鸡尾酒爱好者设计的查询和推荐工具。用户可以根据自己已有的材料、偏好或特定条件，快速获取适合的鸡尾酒配方。该应用提供了详细的配方展示，帮助用户轻松制作和享受美味的鸡尾酒。应用基于 TheCocktailDB API，提供了丰富的鸡尾酒配方数据库和精准的查询推荐服务。',
                app_name: 'WhatToMix',
                aim: {
                    header: "项目目标",
                    content: "开发一款基于 TheCocktailDB API 的鸡尾酒查询和推荐应用。用户可以通过输入已有的材料、选择杯型、过滤酒精等条件，系统会智能推荐符合需求的鸡尾酒配方。应用还提供了详细的配方展示，包含配料清单、制作步骤、使用杯具及酒的基本信息。"
                },
                feature: {
                    header: '系统核心功能',
                    dashboard: {
                        title: "常用基酒分类",
                        description: "主页列出常用的六种基酒，用户可以轻松查阅各种基酒可以调制的鸡尾酒以及配方。",
                    },
                    query_functionality: {
                        title: "查询功能",
                        cocktail_search_by_name: "根据名字查询鸡尾酒：用户可输入鸡尾酒名称，快速获取相关配方信息。",
                        ingredient_search_by_name: "根据名字查询材料：用户可输入材料名称，查看该材料可用于哪些鸡尾酒配方。"
                    },
                    ingredient_matching: {
                        title: "材料匹配推荐（MixMatch）",
                        description: "用户可输入自己拥有的材料，系统将智能推荐可行的鸡尾酒配方。"
                    },
                    glass_type_filter: {
                        title: "杯型筛选",
                        description: "用户可选择特定的杯型，系统将推荐适合该杯型的鸡尾酒饮品。"
                    },
                    category_filter: {
                        title: "类别筛选",
                        description: "用户可根据鸡尾酒的类型（如鸡尾酒，普通饮料，Shot等）筛选合适的配方。"
                    },
                    alcohol_content_filter: {
                        title: "是否带酒精筛选",
                        description: "用户可选择是否包含酒精，系统将推荐符合需求的鸡尾酒饮品。"
                    },
                    recipe_display: {
                        title: "配方展示",
                        ingredient_list: "配料清单：详细列出所需材料的名称及用量。",
                        preparation_steps: "制作步骤：提供分步说明，帮助用户轻松制作。",
                        glassware_used: "使用杯具：指明该配方适用的杯型。",
                        basic_cocktail_info: "酒的基本信息：酒名字，类型，IBA 等。"
                    },
                    ingredient_display: {
                        title: "材料展示",
                        description: "为用户提供材料的相关信息，例如（酒精浓度，类型，是否含有酒精）等。同时提供该材料适合的鸡尾酒。"
                    }
                },
                interface: {
                    title: "用户界面",
                }
            },
            meowID: {
                title: '猫咪品种推理器',
                abstract: 'MeowID是一款基于深度学习的猫咪品种识别应用，用户可以通过上传猫咪的图片，应用会自动识别并分类猫咪的品种，并输出每个品种的概率百分比。通过该应用，用户能够轻松了解自己猫咪的品种，同时获得相关的品种信息。',
                app_name: 'MeowID',
                aim: {
                    header: "项目目标",
                    content: "实现基于 PyTorch 的猫咪品种分类模型。该模型使用一个包含猫咪图片的训练数据集，能够对不同品种的猫咪进行精准分类。在模型构建过程中，采用了 ResNet-50 预训练模型，并对其进行了微调，以实现最佳的分类效果。"
                },
                feature:{
                    coreTech:{
                        header: "核心技术",
                        content: [
                            "深度学习框架: 本项目基于 PyTorch 构建了猫咪品种分类模型。为了提高分类的准确性，采用了 ResNet-50 预训练模型，并进行了 微调 (Fine-tuning)，以适应猫咪品种的特定分类需求。",
                            "模型封装与 API 提供: 模型通过 Flask 构建为一个 Web 服务，用户可以通过 HTTP 请求上传猫咪图片，API 会返回猫咪品种的预测结果和对应的概率。"
                        ]
                    },
                    trainResult:{
                        header: "训练和测试结果",
                        trainLoss: "训练损失 (Train Loss)",
                        trainAccuracy: "训练准确率 (Train Accuracy)",
                        testLoss: "测试损失 (Test Loss)",
                        testAccuracy: "测试准确率 (Test Accuracy)",
                    },
                    resultEachClass:{
                        header: "每个品种的模型准确率",
                        EnglishClassName: "英文品种名",
                        ChineseClassName: "中文品种名",
                        accuracy: "准确率 (Accuracy)"

                    },
                    source:{
                        header: "材料来源",
                        datasetSource: {
                            header: "数据集来源",
                            content: "Aml的数据集"
                        },
                        frontEndRepo: {
                            header: "前端vue仓库",
                            content: "GitHub仓库"
                        },
                        backEndRepo: {
                            header: "后端仓库",
                            content: "GitHub仓库"
                        }
                    },
                }
            },
            joFind: {
                title: '招聘平台',
                abstract: 'JoFind是一款全面的招聘平台，旨在为求职者和招聘公司提供高效的匹配和交流功能。求职者可以浏览岗位、投递简历、与HR在线对话；公司可以发布岗位、管理投递申请、查看简历并与求职者互动。',
                app_name: 'JoFind',
                aim: {
                    header: "项目目标",
                    content: "JoFind招聘平台旨在为求职者提供一个便捷的职位搜索和投递平台，同时为招聘公司提供一个高效的招聘管理系统。平台通过多种过滤条件（如薪资范围、工作经验、地区、职位类型等）帮助求职者精准筛选岗位，求职者还可以实时与HR沟通、查询投递状态等。对于招聘公司，平台提供岗位发布、申请管理、简历查看等功能，以优化招聘流程。"
                },
                feature: {
                    header: "系统功能",
                },
                interface:{
                    header:"用户界面",
                    seeker:"求职者界面",
                    company:"公司界面",
                    login:{
                        header: "身份验证",
                        content:"JoFind区分求职者和公司两种角色，能够根据不同用户的需求，提供专属的功能界面和服务内容，确保操作简便、高效且体验友好。"
                    },
                    jobMarket:{
                        header: "岗位浏览",
                        content:"求职者可以通过平台浏览所有发布的招聘信息，并根据自身需求筛选出符合条件的岗位，获取详细的职位描述和公司信息。求职者可直接将个人简历发送至目标岗位，快速参与应聘流程。"
                    },
                    chat: {
                        header: "在线实时对话",
                        content: "求职者与HR均可通过平台实时在线交流。求职者可以提问并进一步了解岗位详情或面试安排；HR可以快速回应求职者的问题，提供招聘信息或安排面试。",
                    },
                    resume: {
                        header: "个人在线简历维护",
                        content: "提供简历在线编辑和存储功能，支持维护个人基本信息，包括期待岗位、实习经历、工作经历、项目经历、教育经历、语言能力等模块。简历内容可以快速更新，确保信息完整准确，从而提高投递效率和成功率。",
                    },
                    applicationRecord: {
                        header: "投递状态查询",
                        content: "求职者可实时查询投递简历后的状态更新。"
                    },

                    jobManage: {
                        header: "公司岗位管理",
                        content: "公司可通过平台快速发布和管理招聘需求，包含岗位职责、要求、薪资范围等详细信息。"
                    },
                    companyManage: {
                        header: "公司信息管理",
                        content: "企业用户可完善公司资料，包括公司简介、行业领域、联系方式等，为求职者提供参考。"
                    },
                    applicationManage: {
                        header: "求职者管理",
                        content: "公司可以查看所有岗位的应聘信息，包括求职者提交的简历和个人资料, 集中管理所有应聘者信息，对比筛选，选择最适合的候选人。支持企业对求职者的投递状态进行更新，例如“简历已查看”“面试邀请”“不符合”等，方便后续沟通。"
                    },

                },
            },

            musicBuddy:{
                title: '基于歌词的音乐探索和推荐系统',
                abstract: '利用歌词、歌词衍生特征、歌曲元数据、用户偏好和收听历史构建音乐探索和智能推荐系统，为用户提供个性化的音乐内容和更准确全面的音乐信息',
                app_name: 'MusicBuddy',
                aim:{
                    header:"项目目标",
                    content: "这个项目回顾了目前市场上的音乐推荐系统，以及各种推荐算法，从而确定关键需求和挑战。该项目旨在利用歌词、歌词衍生特征、歌曲元数据、用户偏好和收听历史构建音乐探索和智能推荐系统，通过可靠的算法和简单易用的可视化界面为用户提供个性化的音乐内容和更准确、更全面的音乐信息。这样可以帮助用户深入了解音乐内容，进一步满足用户的需求，让用户可以轻松浏览、搜索和享受音乐。",
                },
                feature:{
                    header:"系统功能"
                },
                interface:{
                    header:"用户界面",
                    recommendation:{
                        header: "音乐推荐",
                        content:"基于内容过滤以歌词为输入，使用TF-IDF捕获歌词中的关键词、Word2Vec捕获语义、LDA捕获潜在主题, 从而进行音乐推荐。协同过滤基于用户评分数据计算相似用户从而推荐相似用户喜欢的音乐。"
                    },
                    random_recommendation:{
                        header: "随机推荐",
                        content:"系统提供随机音乐供用户探索,通过简单划动切换音乐, 提供关键音乐元数据, 音乐播放以及评分功能。"
                    },
                    integration:{
                        header: "集成Spotify",
                        content:"支持绑定Spotify账户以获取用户听歌历史记录。同时支持Spotify音乐源提供的播放功能。"
                    },
                    metadata:{
                        header: "元数据可视化",
                        content:"应用程序提供各类元数据的可视化,包括歌曲艺人的名字、专辑、背景、热度、标签、发布日期、歌曲持续时间等基本数据。由于版权的原因，无法在网页中显示歌词，而是系统会提供歌词的主题分布和热门关键词。"
                    },
                    search:{
                        header: "关键词搜索",
                        content:"支持通过关键字搜索本地数据库以及Spotify资源, 支持模糊搜索, 提供音乐、艺人、歌词三个维度的结果过滤。"
                    },
                    tag:{
                        header: "标签搜索",
                        content:"支持通过系统数据库提供的标签进行过滤搜索, 标签数量高达30,000+, 音乐、艺人两个维度的结果过滤"
                    },
                    report:{
                        header: "听歌习惯报告",
                        content:"通过分析用户的听歌数据，生成一些报告，包括来自Spotify的收听历史，评分记录以及用户收听音乐的日期和时间分布，用户通常收听的音乐的年份和最能代表用户的标签，关键词和话题。"
                    },

                },
                feedback:{
                    header: "用户反馈",
                    last_update: "最后更新",
                    exploration:{
                        header: "音乐探索的平均分与意见",
                        comments: [
                            "搜索效率不高",
                            "需要提供更多类型的报告",
                            "音频来源不够多样化",
                            "元数据和音频源信息不够全面",
                            "系统的音乐库容量不足",
                            "需要在搜索和标签功能中增加更多筛选和排序选项"
                        ]
                    },
                    recommendation:{
                        header: "音乐推荐的平均分与意见",
                        comments: [
                            "推荐不够高效和准确",
                            "输入数据多样性不足",
                            "缺乏个性化推荐（例如基于情绪、场景或类型）",
                            "缺乏改进未来推荐的反馈机制",
                            "推荐内容重复"
                        ]
                    },
                    system:{
                        header: "软件整体的平均分和意见",
                        comments: [
                            "第三方连接缺乏多样性",
                            "用户体验不够流畅",
                            "需要更好的用户互动（例如，曲目评论、指导信息）",
                        ]
                    },
                    part_of_system:{
                        header: "系统五个主要部分的平均分"
                    }
                }
            },
            netify:{
                title: 'iOS 音乐播放器 (未完成)',
                abstract: '基于网易云音乐API后端项目进行开发的iOS音乐播放器应用程序, 旨在提供简介精美的前端界面, 简化网易云音乐原生音乐的功能和视图繁杂。',
                app_name: 'Netify',
                aim:{
                    header:"项目目标",
                    content: "基于网易云音乐API后端项目进行开发的iOS音乐播放器应用程序, 旨在提供简介精美的前端界面, 简化网易云音乐原生音乐的功能和视图繁杂，让用户可以轻松浏览、搜索和享受音乐。",
                },
                feature:{
                    header:"系统功能"
                },
                interface:{
                    header:"用户界面",
                    login:{
                        header: "连接网易云音乐账号",
                        content:"通过短信验证码安全的登陆网易云音乐账号"
                    },
                    recommendation:{
                        header: "音乐推荐",
                        content:"系统提供随机音乐供用户探索,通过简单划动切换音乐, 提供关键音乐元数据, 音乐播放以及评分功能。"
                    },
                    lyric:{
                        header: "歌词显示",
                        content:"支持绑定Spotify账户以获取用户听歌历史记录。同时支持Spotify音乐源提供的播放功能。"
                    },
                    metadata:{
                        header: "元数据可视化",
                        content:"应用程序提供各类元数据的可视化,包括歌曲艺人的名字、专辑、背景、热度、标签、发布日期、歌曲持续时间等基本数据。"
                    },
                    search:{
                        header: "关键词搜索",
                        content:"支持通过关键字搜索音乐和艺人"
                    },
                    report:{
                        header: "听歌报告",
                        content:"通过用户的听歌数据生成报告。"
                    },

                },
            },
            eWaste:{
                title: '电子设备回收系统',
                abstract: '该项目旨在提供一个中心，可以根据设备的年龄和需求识别设备，并为所有者提供支付数据检索费用的选项',
                app_name: 'ePanda',
                aim: {
                    header:"项目目标",
                    content: '该项目旨在提供一个中心，可以根据设备的年限和需求识别设备，并为所有者提供支付数据检索费用的选项。如果设备可能被转售，例如新的智能手机等，那么中心应该确定所有者可能出售其设备的第三方，第三方为成功转售支付推荐费。卖家仍然可以选择取回数据并清除他们的设备(需要付费)。',
                },
                requirement: {
                    header: '需求分析'
                },
                interface:{
                    header:"用户界面",
                    login:{
                        header: "多端账号",
                        content:"同时支持账号密码, Google, FaceBook三端账号注册和登陆。"
                    },
                    market:{
                        header: "设备市场 & 个人设备管理",
                        content:"系统提供市场功能供用户浏览比价, 轻松管理发布的设备, 查看实时状态等。"
                    },
                    addItem:{
                        header: "设备发布",
                        content:"数据库存储了高达116个品牌的11000+个型号的电子设备以及参数信息, 用户可以轻松链接自己的设备, 上传设备图片以及输入设备成色从而获取第三方报价。"
                    },
                    itemDetail:{
                        header: "设备详情",
                        content:"系统提供详细的设备参数以及第三方给出的收购参考价格, 用户可以接受报价并生成包含设备信息的二维码到第三方进行交易或者选择平台提供的回收服务,附带数据备份服务(付费)"
                    },
                    payment:{
                        header: "支付",
                        content:"数据备份恢复服务需要付费,系统支持Stripe和Paypal两个平台的支付服务。"
                    },
                    dataRetrieve:{
                        header: "数据备份 & 恢复",
                        content:"由工作人员进行数据上传, 用户可以在平台中下载备份的数据或者延长数据保存时间。"
                    },
                    admin:{
                        header: "管理后台",
                        content:"系统支持用户/员工/管理员三种角色, 后两者可以登陆系统后台进行设备管理, 用户发布管理, 用户管理, 查看报告等功能。"
                    },
                },
            },
            cuda:{
                title: '使用OpenMp和Cuda对算法进行并行优化',
                abstract: '利用使用OpenMp和Cuda对三个算法的单线程实现进行优化'
            },
            nGrams:{
                title: '创建传统的 n-grams 语言模型',
                abstract: '从构建哈希表结构开始, 搭建一个基础的n-grams语言模型并通过Swing图形界面可视化。'
            },
            openGL:{
                title: '使用现代OpenGL渲染场景',
                abstract: '使用数据结构和数学来表示和操作3D对象,使用现代OpenGL渲染场景(照明，纹理，动画等)并制作使用图形API的交互式软件。'
            },
            healthyExpert:{
                title: '健康监测安卓APP',
                abstract: '设计和开发一个协作的移动传感系统，连接移动或可穿戴设备，以支持普遍的健康应用',
                app_name: 'HealthyExpert',
                aim:{
                    header:"项目目标",
                    content: "本项目的主要目的是尝试设计和开发一个协作移动传感系统，使用移动设备上的各种传感器收集、分析、存储和使用个人健康数据，以监测人体的生命体征, 提供一个实时监测和跟踪各种健康数据的平台，" +
                        "为广大民众提供更方便、高效、优质的健康服务以支持普适健康。 功能模块包括 步数监测、训练模式、卡路里监测、睡眠监测、心率检测、饮水监测提醒、药物提醒、身体指标监测、目标设定、经期预测、健康咨询、历史记录、健康建议等",
                    stack: {
                        type: "类型",
                        stack: "技术栈",
                        front_end: "前端",
                        back_end: "后端",
                        api_testing: "API测试平台",
                        database: "数据库",
                        server: "服务器",
                        architecture: "架构",
                        editor: "源代码编辑器",
                        version_control: "版本控制",
                        apis: "API 库",
                    }
                },
                dataflow:{
                    header: "系统流程图"
                },
                interface: {
                    authentication: {
                        header: "登陆&注册",
                        content: "用户通过邮箱和密码进行注册和登陆，密码会进行哈希加密后保存到数据库中。"
                    },
                    main: {
                        header: "主页",
                        content: "应用主页展示了不同健康数据模块, 支持查看过去的健康数据，支持健康文章阅读，个人主页记录和监测用户的基本身体指标"
                    },
                    calorie: {
                        header: "卡路里",
                        content: "支持用户添加/修改/删除卡路里摄入和消耗记录。支持设定目标，帮助用户了解他们每天的能量摄入和消耗。"
                    },
                    step: {
                        header: "步数统计",
                        content: "支持在后台持续跟踪步数和时间，并计算距离、消耗的卡路里和行为习惯, 用户可以了解自己每天的运动量是否达标以及自己的活动情况等。"
                    },
                    exercise: {
                        header: "训练",
                        content: "支持用户跟踪不同活动的数据，如步行，跑步，骑自行车等。使用不同的算法处理数据并通过GPS记录运动轨迹。同时支持用户查看每日锻炼达标情况。"
                    },
                    water: {
                        header: "喝水记录",
                        content: "收集用户提供的饮水记录，通过推送通知提醒用户喝水。与预设目标相比，它可以帮助用户更好地管理日常饮用水，随时保持身体水分。"
                    },
                    medication: {
                        header: "吃药提醒",
                        content: "允许用户手动添加药物和设置服药提醒，并根据设定的时间推送通知提醒用户服药，这对于需要长期服药的人来说更加人性化，更容易管理和跟踪服药情况。"
                    },
                    sleep: {
                        header: "睡眠监测",
                        content: "监测睡眠时长和睡眠环境，生成睡眠报告以响应用户，让他们知道他们需要如何改进以获得更好的睡眠，帮助实现睡眠目标并改善整体健康状况。"
                    },
                    heartRate: {
                        header: "心率检测",
                        content: "允许用户监测和记录他们当前的心率，可以让人们了解他们的健康水平、心脏健康和情绪状况，然后使用这些信息做出改变、设置优先级，并朝着更健康的生活迈进"
                    },
                    period: {
                        header: "经期记录",
                        content: "跟踪你的月经周期和每月的变化可以帮助计划生育，预防怀孕。"
                    },


                },
                result: {
                    header: "总结",
                    result: "在实际的开发中，由于时间的限制，无法实现所有预先分析的功能需求，但所有必要的功能和部分值得选择的功能已经实现。对于测试部分，性能测试分数很好，CPU使用率很低，内存使用率和能耗也很低。总的来说，用户对应用软件还是比较满意的，但是还是存在一些bug。此外，用户也对改进提出了宝贵的建议。这个软件可以满足用户的基本需求，帮助用户监测和了解自己的健康状况，从而做出相应的对策。但是，为了更好地满足每个用户的需求，提供更好的用户体验，还有很大的改进空间。",
                    significant: "普及医疗保健计划是解决当前医疗保健系统面临的一系列危机和挑战的方案之一。它可以定义为通过消除位置、时间和其他限制，同时提高覆盖范围和质量，随时随地向任何人提供医疗保健。相比之下，移动健康是使用手机或其他无线设备与软件相结合来支持医疗保健，这可以帮助改善疾病预防和自我管理，提高医疗保健的质量和效率，降低医疗保健成本和增加。"
                },

            },
            birdWatching:{
                title: '观鸟论坛渐进式网页应用',
                abstract: '一个“观鸟”渐进式web应用程序，为用户记录和观察鸟类，并帮助辨认。',
                app_name: 'BirdWatching',
                aim:{
                    header:"项目目标",
                    content: "这个项目的目标是设计和开发一个“观鸟”渐进式web应用程序，为用户记录和观察鸟类，并帮助辨认。使用本网站，用户可以添加新的目击事件，查看目击事件，还可以对识别进行评论并连接知识图谱以提供官方资讯。",
                },
                interface:{
                    header:"用户界面",
                    create:{
                        header: "创建记录",
                        content:"系统支持记录观鸟日期和时间、位置(即地理位置)、目击事件的(简短)描述、识别(可能是未知或不确定的)、照片。"
                    },
                    chat:{
                        header: "实时聊天",
                        content:"系统支持用户进行实时聊天并链接到每个目击事件。"
                    },
                    location:{
                        header: "Google地图集成",
                        content:"系统支持Google提供的互动地图, 帮助更清晰的记录目击事件发生的地点。"
                    },
                    offline:{
                        header: "离线可用",
                        content:"系统支持在线和离线交互, 当系统从离线恢复到在线时, 信息会自动同步。"
                    },
                    DBPedia:{
                        header: "DBPedia知识库支持",
                        content:"系统会自动将已识别的目击连接到DBPedia知识库, 获取官方的详细信息。"
                    }
                },
            },
            tourVisitView:{
                title: '游客参观系统',
                abstract: '设计、构建和评估一个基于城市的个人照片管理应用程序，使用户能够接收，访问和编辑相关信息。'
            },
            ros:{
                title: 'ROS机器人',
                abstract: '使用ROS在TurtleBot3华夫饼上开发一系列机器人行为，以满足多项任务如自主避障，迷宫导航，自主检测搜索等。'
            },
            theBigWalk:{
                title: '马拉松追踪系统',
                abstract: '为马拉松活动设计一个系统，从而有效的跟踪参与者提高参与者的安全和福祉，确保活动顺利进行。',
                app_name: 'The Big Walk',
                background: {
                    header: "项目背景",
                    content: '“TheBigWalk”是由谢菲尔德大学校友会及校友会合办的筹款挑战活动，每年6月举行。到2022年，将有多达400名员工、' +
                            '校友(以前的学生)和公众人士乘坐地铁前往山顶区30公里或50公里的小道为大学的遗传疾病研究筹集资金。为了确保他们的安全，' +
                        '每个参与者都需要在路线的不同阶段被跟踪。目前的系统是给每个参与者分配一个戴在腕带上的号码。在每个检查站，' +
                        '一名法警在他们通过时标记他们的号码。如果有参与者没有通过，首席元帅会收到警报，他将联系可能迷路或受伤的参与者。'
                },
                aim: {
                    header: "项目目标", // 这是标题，可以翻译为其他语言
                    content: {
                        paragraph1: "目前的系统耗时长，容易出现人为错误，并且在编警之间的沟通中存在困难。对于未来的Big Walk活动，主办方希望:",

                        listItems: [
                            "一种有效的跟踪参与者的方法，通过标记任何潜在的伤害或“迷路”的步行者/跑步者，提高参与者的安全和健康",
                            "一种跟踪谁开始了活动，谁退出了该活动，谁可能会迷路，谁已经完成了活动的方法",
                            "为参赛者提供路线指导，告诉他们是领先、落后还是达到了预期速度，以及“终点时间”。",
                            "一个数据中心，存储每个参与者的进度数据，“实时”更新，可供所有元帅和活动组织者查看"
                        ]
                    }
                },
                features:{
                    header: "功能总结",
                    brief: "根据和客户的沟通，该系统主要分为三种不同的角色（参赛者、法警、主办方），每种角色对应不同的业务逻辑与需求。",
                    host:{
                        role: '举办单位',
                        ability: [
                            '主办方可以创建/修改/终止活动',
                            '主办方可以赋予其他用户权限',
                            '主办方可以查看所有账号以及信息',
                            '主办方可以接受并处理参赛者申请的帮助请求',
                            '...'
                        ]
                    },
                    marshall:{
                        role: '法警',
                        ability: [
                            '法警可以选择所在的检查点',
                            '法警可以切换所在的检查点',
                            '法警可以查看准备到达所在检查点的参赛者',
                            '法警可以手动帮参赛者签到',
                            '法警可以终止/暂停/恢复工作状态',
                            '...'
                        ]
                    },
                    walker:{
                        role: '参赛者',
                        ability: [
                            '参赛者可以注册登录以登记参赛信息',
                            '参赛者可以选择需要参加的活动以及路线',
                            '参赛者可以点击开始按钮开始记录活动过程',
                            '参赛者经过检查点时点击签到按钮，系统会根据目前定位判断参赛者是否到达检查点',
                            '参赛者可查看到达下一个检查点的路线',
                            '参赛者可查看当前的排名',
                            '参赛者可选择是否加入排行榜',
                            '参赛者可查看进度和时间',
                            '参赛者可发起帮助请求',
                            '...',
                        ]
                    },
                },
                feedback: {
                    header: '客户评价',
                    content: '一个非常好的系统，很容易导航，视觉上很吸引人。显然，在编程、系统美学、消息和使用的语言方面都花了很多心思。当有人想要退出时，添加的信息是很棒的，真正抓住了筹款的乐趣和关怀精神。这个系统看起来与现有的应用程序非常相似，所以我觉得步行者可以轻松使用这个应用程序。系统事件有一个排行榜，玩家可以选择加入，这是一个“很好的选择”'
                },
                price:{
                    name: '软件屋大奖',
                    content: '该奖项每年颁发一次，以表彰为现实世界客户开发的软件。学生们在团队中竞争，以开发最有效的软件，遵循敏捷开发过程，与客户密切合作。这个学生是获胜团队的一员，被该团队的客户认为是获胜团队的一员。'
                }
            },
            textProcessing:{
                title: '文本分析',
                abstract: '利用机器学习构建一个文本分析系统，对文本进行信息提取，信息检索以及文本情感语义分析'
            },
            dataDriven:{
                title: '棋盘图分类器',
                abstract: '构建并评估一个系统，通过数据驱动等方法分析具有噪音的的棋盘图并报告每个方格上的棋子。'
            },
            rentSystem:{
                title: '基于Swing的租房系统',
                abstract: '该系统管理一系列短期小物业，不同角色可访问不同的资料并将管理酒店的预订和评论。'
            },
            haskell:{
                title: '基于Haskell编写的纸牌游戏',
                abstract: '使用Haskell语言编写8-off纸牌游戏和蜘蛛纸牌游戏的代码，并分析其性能'
            },
            neteaseMusic:{
                title: '仿网易云音乐',
                abstract: '利用网易云音乐官方API搭建的一个仿网易云音乐网页端应用'
            },
            eleme:{
                title: '仿饿了么',
                abstract: '仿饿了么外卖APP网页端应用'
            },
        },
        motorbike: {
            dimension: "尺寸",
            weight: "重量",
            engine:"发动机",
            power:"最大功率",
            torsion:"最大扭矩",
            brake:"刹车系统",
        },
        cat: {
            name: '蛋挞',
            age: '4 岁',
            age_title: '年龄',
            weight: '5.7 KG',
            weight_title: '体重',
            sex: '女孩',
            sex_title: '性别'
        },
        contact: {
            header: "准备好聊天了吗?",
            name_label: "姓名:",
            email_label: "邮箱:",
            title_label: "标题:",
            message_label: "消息:",
            send_btn: "发送",
            notification:{
                success: "太棒了! 感谢你的邮件，我会尽快回复你。",
                failed: "很遗憾邮件发送失败，请再试一次或者手动给我发邮件。",
            }
        }
    }
}